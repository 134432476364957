#inicio {
  position: relative;
  background-image: url("../../img/fondo.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 47% 0%;
  background-attachment: scroll;
  width: 100%;
  height: calc(100vh - 3rem);
  
  .eri{
    text-transform: uppercase;
    position: absolute;
    top: 25vh;
    left: 1rem;
    font-size: 1rem;
    text-shadow: 1px 1px 1px $detalle6;
    cursor: pointer;
    &:hover{
      opacity: .7;
      color: white;
      text-shadow: 4px 4px 8px #888888; 
    }
  }
  .ceci{
    text-transform: uppercase;
    position: absolute;
    top: 35vh;
    right: 1rem;
    font-size: 1rem;
    text-shadow: 1px 1px 1px $detalle6;
    cursor: pointer;
    &:hover{
      opacity: .7;
      color: white;
      text-shadow: 4px 4px 8px #888888; 
    }
  }
  @include desktop {
    background-position: 50% 80%;
    height: 100vh;
    .eri  {
      position: absolute;
      top: 50vh;
      left: 4rem;
      font-size: 1rem;
      text-shadow: 1px 1px 1px $detalle6;
      // font-family: "Indie Flower";
    }
    .ceci  {
      position: absolute;
      top: 50vh;
      right: 4rem;
      font-size: 1rem;
      text-shadow: 1px 1px 1px $detalle6;
      // font-family: "Indie Flower";
    }
  }
  .flecha {
    position: absolute;
    bottom: .5rem;
    left: 49%;
    font-size: 1.3rem;
    text-shadow: 1px 1px 1px $detalle6;
    z-index: 2;
  }
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3rem;
    background-color: $detalle4;
    z-index: 1;
  }
  
}
.sinBorde {
  --bs-gutter-x: 0rem;
}


