
#calendario {
  min-height: calc(100vw / .82);
  border-bottom: 2.5rem solid $detalle4;
  @include desktop {
    min-height: calc(100vw / 2.82);
  }
  position: relative;
  
  .flecha {
    position: absolute;
    bottom: -2.5rem;
    left: 49%;
    font-size: 1.3rem;
    text-shadow: 1px 1px 1px $detalle6;
  }
  .btnCalendario {
    width: auto;
    text-align: center;    
    font-weight: bold;
    color: $letra;
    height: 3rem;
    background-color: $detalle4;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
      .row {
      --bs-gutter-x: 0rem;
    }
  .slick-list {
    border: none;
  }
  
  .imgMedida {
    position: relative;
    display: inline-block;
  }
  
  .medida {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 10rem;
    text-align: center;
    background-color: rgba(51, 49, 49, 0.9);
    white-space: nowrap;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3), 2px 2px 8px rgba(255, 255, 255, 0.3);
    transition-duration: 0.4s;
    &:hover {
        opacity: 0.7;
        box-shadow: 6px 6px 12px #0c0c0c, 6px 6px 24px rgba(255, 255, 255, 0.3);
    }
}


  
  .fecha {
   font-size: .8; 
   color: $detalle;
  }
  .evento{
   @include desktop{
    img{
      padding-left: 1rem;
      padding-right: 1rem;
      
    }}
  }
  .carousel{
    
    padding-left: 0rem;
    padding-right: 0rem;
    background-color: $detalle4;
    border: none!important;
    @include desktop{
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }
  }
}
.detalle{
  background-color: $detalle;
  min-height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  @include desktop{
    align-items: center;
  }
  .contenedor{
  max-width: 1000px;
  }
  .carousel{
    padding-left: 0rem;
    padding-right: 0rem;
    background-color: $detalle4;
    border: none!important;
    @include desktop{
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }
  }
  .card{
    background-color: $detalle;
    border: none;
    border-radius: none;
  }
  h1{
    font-size: 1.3rem;
    font-weight: bold;
  }
  
}
.instagram{
  *{color: $letra!important;
    font-family: "Cutive Mono"!important;
  letter-spacing: 1px!important;
  font-size: 1rem!important;

    }  
    p{
      margin-bottom: 0rem!important;
    }
  }
    