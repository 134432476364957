
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: rgba(0, 0, 0,.3) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.pad {
  padding-right: 1.2rem;
  padding-left: 1.2rem;
  border: none;
  @include desktop{
    padding-right: 2.9rem;
    padding-left: 2.9rem;
      
  }
  a {
    text-decoration: none;
  }
  .card {
    // padding: 1rem;
    border: none;
  }
}

