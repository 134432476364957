#proyectos {
  min-height: calc(100vw / 0.7);
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-bottom: 3rem solid $detalle;
  @include desktop {
    flex-direction: row;
    min-height: calc(100vw / 2.77);
  }
  .flecha {
    position: absolute;
    bottom: -2.5rem;
    left: 49%;
    font-size: 1.3rem;
    text-shadow: 1px 1px 1px $detalle6;
  }
  .proyecto {
    position: relative;
    display: block; /* o block, dependiendo del diseño */
  }

  .proyecto img {
    display: block;
    width: 100%;
    height: auto;
  }

  .proyecto .boton {
    min-width: 10rem;
    text-align: center;
    position: absolute;
    top: 50%;
    // font-weight: bold;
    left: 50%;
    transform: translate(-50%);
    background-color: $detalle8;
    padding: 10px;
    margin: 0;
    white-space: nowrap;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    // color: $fondo;
  }
}
#libreta {
  background-color: rgba(0, 0, 0);
  .libreta {
    margin-top: 4rem;
    display: flex;
    align-items: center;
    min-height: calc(100vh - 10rem);
  }
  .carousel {
    // background-color: black;
    border-radius: 2rem;
    padding-left: 0rem;
    padding-right: 0rem;
    border: none !important;
    @include desktop {
      padding-left: 0rem;
      padding-right: 3rem;
      border-radius: 1rem;
    }
  }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    opacity: 0.75;
    color: $letra !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .texto {
    // background-col-or: rgba(0, 0, 0, .95);
    padding: 2rem;
    color: #fdfdfd;
    a {
      color: $letra;
      text-decoration: none;
    }

    h1 {
      font-size: 1.1rem;
      font-weight: bold;
    }
  }
  @include desktop {
    padding: 3rem;
  }
}

#vivo {
.boton{
  align-self: center;
}
  a {
    color: $letra;
    text-decoration: none;
  }
  .row {
    --bs-gutter-x: 0rem;
  }
  background-color: $fondo;
  margin: 0;
  padding: 0;
  .vivo {
    padding: 0;
    margin-top: 4rem;
    display: flex;
    align-items: center;
  }
  .carousel {
    padding-left: 0rem;
    padding-right: 0rem;
    border: none !important;
    @include desktop {
      // margin: 0;
      padding: 0rem;
    }
  }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    opacity: 0.75;
    color: $letra !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .tapa {
    border-radius: 2rem;
  }
  .texto {
    // background-col-or: rgba(0, 0, 0, .95);
    padding: 2rem;
    color: #fdfdfd;
    a {
      color: $letra;
      text-decoration: none;
    }

    h1 {
      font-size: 1.1rem;
      font-weight: bold;
    }
    h2 {
      font-size: 1.1rem;
    }
  }
  .epigrafe {
    padding: 2rem;
    font-style: italic;
    text-align: center;
  }
  @include desktop {
    padding: 0rem;
  }

  .disco {
    background-color: rgba(247, 241, 227, 255);

    .textoDisco {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 0.5rem;
      @include desktop{
        padding: 2rem;
      }
      h2 {
        font-size: 1.1rem;
        color: #2a2a29;
        word-spacing: normal;
        letter-spacing: 0;
        .ficha {
          font-size: 1.1rem;
          color: #2a2a29;
          font-weight: bold;
          font-style: italic;
        }
      }
      h1 {
        font-size: 1.2rem;
        color: #2a2a29;
        font-weight: bold;
      }
    }
  }
  

    
  .medra {
    display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin-top: 0.5rem;
    .botonMedra {
     align-self: center;
      width: fit-content;
      color: $fondo;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 0.8rem;
      text-decoration: none;
      padding: 1rem;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      border-radius: 1rem;
      cursor: pointer;
      box-shadow: 2px 2px 4px #888888;
      transition-duration: 0.4s;
      &:hover {
        opacity: 0.7;
        color: white;
        box-shadow: 4px 4px 8px #888888;
      }
      background-color: rgba(255, 255, 255, 0.8); /* opcional */
    }
    margin: 2rem;
  }
  .fondoGris {
    background-color: rgba(247, 241, 227, 255);
  }
}
