#admin{
    *{
        background-color: white!important;
        color: black !important;
    }
    
}
.create{
 *{  
    // background-color: white!important;
    color: black !important;
}
.btnCreate{
    background-color: $detalle8;
    color: $letra!important;

}

}
