
  @import url('https://fonts.googleapis.com/css2?family=Cutive+Mono&family=Indie+Flower&family=Maven+Pro:wght@400;500;600;700;800&family=Special+Elite&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");

// variables 
$fondo: rgba(85, 107, 47,1);
$letra: #fdfdfd;
$detalle: rgba(85, 107, 47, 1);
$detalle8: rgba(85, 107, 47, .8);
$detalle6: rgba(85, 107, 47, 0.6);
$detalle4: rgba(85, 107, 47, 0.4);
$detalle2: rgba(85, 107, 47, 0.2);


// mixin 
@mixin desktop {
  @media screen and (min-width: 768px) {
    @content;
  }
}
@mixin mobile {
  @media screen and (max-width: 767.9px) {
    @content;
  }
}

* {
  margin: 0;
  padding: 0;
  color: $letra;
  font-family: "Cutive Mono";
  letter-spacing: 1px;
  }

.negrita{
  font-weight: bold;
}
.boton{
  width: fit-content;
  background-color: $detalle;
  color: $letra;
  text-transform: uppercase;
  font-size: .8rem;
  text-decoration: none;
  padding: 1rem;
  margin-top: .5rem;
  margin-bottom: .5rem;
  border-radius: 1rem;
  cursor: pointer;
  box-shadow: 2px 2px 4px #888888;
  transition-duration: 0.4s;
  &:hover{
    opacity: .7;
    color: white;
    box-shadow: 4px 4px 8px #888888; 
  }
  i{
    color: $letra;
    margin: .5rem;
  }
}



// LOADER
.contenido {
  width: 100%;
  height: 96vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255,0);
  padding-bottom: 5rem;
  @include desktop{
    height: 100vh;
  }
  i {
    position: absolute;
    font-size: 2rem;
    color: $detalle;
  }
}

.spinner {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 5px solid $letra;
  background: transparent;
  border-top-color: $detalle6;
  border-bottom-color: $detalle6;
  border-right-color: $detalle6;
  border-left-color: $letra;
  animation: spin 4s linear infinite;
  position: relative;
}

@keyframes spin {
  0% {
    background: transparent;
    transform: rotate(720deg);
  }
}

.cajaUpload{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 5rem;
}
.cuadro{
  border-bottom: 2px solid grey;
}
.btnCant {
  border: none;
  font-size: .8rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
  transition-duration: 0.4s;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
    box-shadow: 4px 4px 8px #888888; 
  }
}
.gris{
  color: rgba(128, 128, 128, 0.7);
  font-size: .8rem;
}
.inputStock{
  width: 2rem;
  text-align: center;
}

.input-group {
  justify-content: flex-end;
 
}
.descripcion{
  p{
  margin-bottom: 0;}
}

// .modal{
//   z-index: 10000;
  
// }



.modal-content{
  margin-top: 4rem;
  box-shadow: 4px 4px 8px #888888;
  
}
.modal-title {
  color: $fondo;
  font-weight: bold;
  text-transform: uppercase;
}
.modal-body{
  p{
    color: $fondo;
    font-weight: 600;
  }
}
