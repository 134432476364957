#trayectoria {
  // min-height: calc(100vw / 1);
  position: relative;
  display: flex;
  height: 100%;
  border-bottom: 3rem solid $detalle;
  background-color: $fondo;
  color: $letra;
  font-size: 1rem;
  line-height: 1.5!important;
  @include desktop {
    min-height: auto;
  }
  h3{
    font-size: 1.2rem;
    font-weight: bold;
  }
  .flecha {
    position: absolute;
    bottom: -2.5rem;
    left: 49%;
    font-size: 1.3rem;
    text-shadow: 1px 1px 1px $detalle6;
  }
  p{
    line-height: 1.5!important;
  }
  
  .iframe {
    min-height: calc(100vw / 1.96);
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
    border-radius: 2rem;
    @include desktop{
      min-height: auto;
    }
  }
  .redes {
    display: flex;
    justify-content: space-around;
    border-top: 1px solid $letra;
    border-bottom: 1px solid $letra;
    padding: 1rem;
    flex-direction: column;
    align-items: stretch;
    @include desktop{
      flex-direction: row;
      
    }
    a {
      text-decoration: none;
      font-size: 1rem;
      color: $letra;
    }
  } 
  
}